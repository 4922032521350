export const sentryDefaultConfig = {
	dsn: 'https://356561aa08235801169a2889dbf96ef5@o4507146791747584.ingest.de.sentry.io/4507525529272400',
	tracesSampleRate: 0.1,
	autoSessionTracking: false,
	enabled: process.env.NODE_ENV === 'production' && !process.env.NEXT_PUBLIC_PREVIEW_ENABLED,
	environment: process.env.NODE_ENV,
	maxBreadcrumbs: 10,
	hideSourceMaps: true,
	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,
	// For all available options, see:
	// https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/
	// Upload a larger set of source maps for prettier stack traces (increases build time)
	widenClientFileUpload: true,
	ignoreErrors: [
		'TypeError: Failed to fetch',
		'TypeError: NetworkError when attempting to fetch resource.',
		`TypeError: undefined is not an object (evaluating 'window.fcWidget.isOpen')`,
	],
	release: `fortnox-web@${process.env.BUILD_TAG}`,
};
